import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ApprovalAssetBreadcrumbs.less';

type ApprovalAssetBreadcrumbsProps = {
  campaignId: string;
  campaignName: string;
};

export default function ApprovalAssetBreadcrumbs({
  campaignId,
  campaignName
}: ApprovalAssetBreadcrumbsProps) {
  const navigate = useNavigate();

  return (
    <div className="approval-assets-breadcrumbs">
      <div
        className="approval-assets-breadcrumbs-item"
        onClick={() => navigate(`/campaigns/single/${campaignId}`)}
      >
        <span className="approval-assets-breadcrumbs-name">{campaignName}</span>
      </div>
      <div className="approval-assets-breadcrumbs-item">
        <span className="approval-assets-breadcrumbs-name">Media list</span>
      </div>
    </div>
  );
}
